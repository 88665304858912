import React, { useEffect, useState } from "react";
import style from "./bloges.module.css";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { GoLaw } from "react-icons/go";

const Bloges = () => {
  useEffect(() => {
    document.title = "Hammer & Chhattery | Services";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <NavBar />
      <div className={style.outer}>
        <div className={style.container}>
          <h1 className={style.Heading1}>Our Sevices</h1>
          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Legal Consultation & Advisory:</h2>
            </div>
            <p className={style.abouttext}>
              Provide expert legal consultation for a range of issues, from
              personal legal matters to complex corporate challenges. Tailor
              advice to align with client objectives and industry standards.
              Offer strategic insights to navigate intricate legal frameworks
              effectively. Assist in understanding legal implications and
              formulating strategies to address them. Ensure clients receive
              informed guidance for optimal decision-making.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Complex Litigation:</h2>
            </div>
            <p className={style.abouttext}>
              Represent clients in high-profile litigation cases, managing all
              aspects from inception to resolution. Develop comprehensive legal
              strategies to address intricate disputes and advocate for client
              interests. Utilize extensive legal knowledge and experience to
              handle complex arguments and evidence. Coordinate with expert
              witnesses and legal teams to strengthen case positions. Strive for
              favorable outcomes in challenging legal scenarios.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>
                Contract Drafting & Negotiation:
              </h2>
            </div>
            <p className={style.abouttext}>
              Draft and negotiate comprehensive contracts to ensure clarity and
              protection for all parties involved. Address legal and business
              requirements through meticulous document preparation. Facilitate
              negotiations to achieve fair and balanced terms. Review and amend
              contracts to safeguard client interests and prevent potential
              disputes. Provide expert guidance to support effective and legally
              sound agreements.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Corporate Governance:</h2>
            </div>
            <p className={style.abouttext}>
              Advise businesses on best practices for corporate governance to
              ensure compliance and enhance operational efficiency. Develop
              policies and procedures that align with legal requirements and
              ethical standards. Assist in structuring boards and committees for
              effective oversight and decision-making. Conduct compliance audits
              to identify and address potential risks. Support companies in
              maintaining transparency and accountability in corporate
              operations.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Employment Law:</h2>
            </div>
            <p className={style.abouttext}>
              Offer guidance on employment law matters, including hiring,
              termination, and workplace policies. Draft and review employment
              contracts to ensure compliance with legal standards. Advise on
              employee rights and employer obligations to prevent disputes.
              Represent clients in employment-related claims and negotiations.
              Provide support in developing fair and legally compliant HR
              practices.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>
                Intellectual Property Protection:
              </h2>
            </div>
            <p className={style.abouttext}>
              Assist clients in securing and protecting intellectual property
              rights, including patents, trademarks, and copyrights. Draft and
              file applications to establish and defend IP rights. Offer
              strategic advice on IP portfolio management and enforcement.
              Handle disputes related to IP infringement and licensing. Ensure
              clients’ innovations and creative works are safeguarded against
              unauthorized use.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Real Estate Transactions:</h2>
            </div>
            <p className={style.abouttext}>
              Provide legal services for real estate transactions, including
              buying, selling, and leasing properties. Conduct due diligence and
              draft contracts to facilitate smooth transactions. Negotiate terms
              and resolve issues related to property deals. Advise on compliance
              with local and national real estate regulations. Ensure all legal
              aspects of property transactions are handled efficiently and
              effectively.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Family Law:</h2>
            </div>
            <p className={style.abouttext}>
              Offer legal support in family law matters, including divorce,
              child custody, and property division. Provide compassionate and
              effective representation in sensitive family disputes. Draft and
              negotiate settlement agreements to resolve conflicts amicably.
              Advise on legal rights and obligations related to family issues.
              Strive to achieve fair and just outcomes in complex family law
              cases.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Tax Law:</h2>
            </div>
            <p className={style.abouttext}>
              Provide expert advice on tax matters, including compliance,
              planning, and disputes. Assist clients in understanding and
              managing tax obligations effectively. Represent clients in
              negotiations with tax authorities and handle tax-related
              litigation. Develop strategies to optimize tax positions and
              minimize liabilities. Ensure adherence to current tax laws and
              regulations for individuals and businesses.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Regulatory Compliance:</h2>
            </div>
            <p className={style.abouttext}>
              Guide clients through regulatory requirements and ensure
              compliance with industry standards. Conduct regulatory audits to
              identify and address compliance gaps. Develop and implement
              compliance programs tailored to specific industries. Represent
              clients in interactions with regulatory bodies and manage
              compliance-related disputes. Stay abreast of changes in
              regulations to provide up-to-date advice and support.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Mergers & Acquisitions:</h2>
            </div>
            <p className={style.abouttext}>
              Advise on legal aspects of mergers and acquisitions, including due
              diligence and transaction structuring. Draft and negotiate
              agreements to facilitate successful business combinations. Manage
              legal processes involved in merging or acquiring companies. Assess
              and mitigate risks associated with M&A transactions. Ensure
              compliance with regulatory requirements and support integration
              efforts post-transaction.
            </p>
          </div>
          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Financial & Securities Law:</h2>
            </div>
            <p className={style.abouttext}>
              Provide comprehensive legal support in financial and securities
              matters, including investment regulation and compliance. Advise
              clients on structuring financial transactions and managing
              securities portfolios. Represent clients in regulatory
              investigations and enforcement actions. Develop strategies to
              mitigate risks and ensure adherence to financial laws. Support in
              navigating complex financial regulations and securities markets.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Healthcare Law:</h2>
            </div>
            <p className={style.abouttext}>
              Offer legal guidance in healthcare law, including regulatory
              compliance and healthcare policy. Advise healthcare providers on
              legal issues related to patient care, medical records, and
              liability. Represent clients in disputes involving healthcare
              regulations and practices. Develop compliance programs to address
              healthcare-specific legal requirements. Ensure that healthcare
              operations adhere to applicable laws and standards.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Insurance Law:</h2>
            </div>
            <p className={style.abouttext}>
              Provide expert legal services in insurance law, covering policy
              drafting, claims, and disputes. Advise insurers and policyholders
              on rights, obligations, and coverage issues. Represent clients in
              insurance-related litigation and negotiations. Develop strategies
              for resolving claims disputes and regulatory compliance. Ensure
              all insurance practices align with legal and regulatory standards.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Environmental Law:</h2>
            </div>
            <p className={style.abouttext}>
              Offer legal counsel on environmental regulations and compliance
              for businesses and organizations. Advise on environmental impact
              assessments and sustainability initiatives. Represent clients in
              disputes involving environmental regulations and enforcement
              actions. Develop policies and strategies to manage environmental
              risks and ensure compliance. Stay updated on environmental laws to
              provide effective legal solutions.
            </p>
          </div>

          <div>
            <div className={style.flex}>
              <GoLaw className={style.icons} />
              <h2 className={style.Heading}>Immigration Law:</h2>
            </div>
            <p className={style.abouttext}>
              Provide legal assistance with immigration matters, including visa
              applications and citizenship processes. Advise clients on
              immigration regulations and eligibility requirements. Represent
              clients in immigration hearings and legal proceedings. Develop
              strategies to address complex immigration issues and ensure
              compliance. Support individuals and businesses in navigating the
              immigration system effectively.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Bloges;
