import React, { useEffect, useRef, useState } from "react";
import emailjs from "emailjs-com"; // EmailJS import
import NavBar from "../Components/NavBar";
import style from "./contsctus.module.css";
import { TbMessage2 } from "react-icons/tb";
import { HiOutlinePhone } from "react-icons/hi2";
import { MdAccessTime } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import Footer from "../Components/Footer";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    document.title = "Hammer & Chhattery | Contact Us";
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_igue6pr", // Replace with your service ID
        "template_de7x8j7", // Replace with your template ID
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
        },
        "ah67QG2iZjznFr_Qt" // Replace with your user ID
      )
      .then(
        (response) => {
          console.log(
            "Email successfully sent!",
            response.status,
            response.text
          );
          alert("Message sent!");
        },
        (err) => {
          console.error("Failed to send email. Error:", err);
          alert("Failed to send the message. Please try again.");
        }
      );

    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <div>
      <NavBar />
      <div className={style.outer}>
        <div className={style.container}>
          <div className={style.contactUsContainer}>
            <h1 className={style.contactHeading}>Contact Us</h1>

            <p className={style.contactIntro}>
              We value your inquiries and are here to assist you. Whether you
              have a question about our services, need legal advice, or want to
              schedule a consultation, feel free to reach out to us using the
              details below.
            </p>

            <div className={style.contactDetails}>
              <div className={style.flex}>
                <HiOutlinePhone className={style.icons} />
                <h2 className={style.subHeading}>Get in Touch</h2>
              </div>

              <p className={style.contactText}>
                <strong>Email:</strong> attorneyamir@gmail.com <br />
                <strong>Phone:</strong> +92-42-36311007 <br />
                {/* <strong>Fax:</strong> +1 (555) 765-4321 */}
              </p>
            </div>

            <div className={style.addressSection}>
              <div className={style.flex}>
                <SlLocationPin className={style.icons} />
                <h2 className={style.subHeading}>Our Office</h2>
              </div>
              <p className={style.contactText}>
                <strong>Address:</strong> <br />
                58 off Lawrence Road, <br />
                Eissa Heights <br />
                Lahore, Pakistan
              </p>
            </div>

            <div className={style.officeHours}>
              <div className={style.flex}>
                <MdAccessTime className={style.icons} />
                <h2 className={style.subHeading}>Office Hours</h2>
              </div>

              <p className={style.contactText}>
                <strong>Monday - Friday:</strong> 9:00 AM - 6:00 PM <br />
                <strong>Saturday:</strong> 10:00 AM - 2:00 PM <br />
                <strong>Sunday:</strong> Closed
              </p>
            </div>

            <div className={style.contactForm}>
              <div className={style.flex}>
                <TbMessage2 className={style.icons} />
                <h2 className={style.subHeading}>Send Us a Message</h2>
              </div>

              {/* Form for sending a message via EmailJS */}
              <form className={style.form} onSubmit={handleSubmit}>
                <div className={style.formGroup}>
                  <label htmlFor="name" className={style.formLabel}>
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name" // Corresponding to formData
                    className={style.formInput}
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange} // Handle change
                    required
                  />
                </div>

                <div className={style.formGroup}>
                  <label htmlFor="email" className={style.formLabel}>
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email" // Corresponding to formData
                    className={style.formInput}
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange} // Handle change
                    required
                  />
                </div>

                <div className={style.formGroup}>
                  <label htmlFor="message" className={style.formLabel}>
                    Message:
                  </label>
                  <textarea
                    id="message"
                    name="message" // Corresponding to formData
                    className={style.formTextarea}
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange} // Handle change
                    required
                  ></textarea>
                </div>

                <button type="submit" className={style.formButton}>
                  Submit
                </button>
              </form>
            </div>

            <div className={style.mapSection}>
              <div className={style.flex}>
                <SlLocationPin className={style.icons} />
                <h2 className={style.subHeading}>Find Us Here</h2>
              </div>

              <div className={style.map}>
                {/* You can embed a Google Map here */}
                <iframe
                  style={{ border: "none", borderRadius: "16px" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3400.1140212601917!2d74.32257368309308!3d31.548485279249107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391904ba69d74919%3A0x653770d617459f6a!2s58%20Lawrence%20Road%2C%20Jinnah%20Town%2C%20Lahore%2C%20Punjab%2054000%2C%20Pakistan!5e0!3m2!1sen!2s!4v1724396758389!5m2!1sen!2s"
                  width="100%"
                  height="450"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
