import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import styles from "./navBar.module.css";
import classNames from "classnames";
import logo from "../../assests/logo22.png";

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <a href="/" className={styles.navbarLogo}>
          <img src={logo} className={styles.logo} />
        </a>
        {/* <div className={styles.menuIcon} onClick={toggleMenu}>
          <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </div> */}
        <ul className={classNames(styles.navMenu, { [styles.active]: isOpen })}>
          <li className={styles.navItem}>
            <Link
              to="/"
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive("/"),
              })}
            >
              Home
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              to="/aboutUs"
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive("/aboutUs"),
              })}
            >
              About
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              to="/service"
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive("/service"),
              })}
            >
              Services
            </Link>
          </li>
          <li className={styles.navItem}>
            <Link
              to="/contactUs"
              className={classNames(styles.navLinks, {
                [styles.activeLink]: isActive("/contactUs"),
              })}
            >
              Contact
            </Link>
          </li>
        </ul>
        <div>
          <FaBars
            className={styles.menuIcon}
            onClick={() => setIsMenuOpen(true)}
          />
          {isMenuOpen && (
            <div
              className={`${styles.mobileMenu} ${
                isMenuOpen ? styles.open : ""
              }`}
            >
              <div className={styles.menuHeader}>
                <img
                  src={logo}
                  className={styles.navbarLogosm}
                  style={{ margin: "0px" }}
                />
                <IoClose
                  className={styles.Headerclose}
                  onClick={() => setIsMenuOpen(false)}
                />
              </div>
              <div className={styles.mobileMenuContent}>
                <Link
                  className={styles.menulist}
                  to="/"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Home
                </Link>
                <Link className={styles.menulist} to="/aboutUs">
                  About Us
                </Link>
                <Link className={styles.menulist} to="/service">
                  Services
                </Link>
                <Link className={styles.menulist} to="/contactUs">
                  Contact us
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
