// src/pages/HomePage.js
import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import style from "./home.module.css";
import styles from "./home.module.css";
import book from "../assests/image.png";
import file from "../assests/18th-amendment.pdf";
import { FiDownload } from "react-icons/fi";
import Spinner from "../Components/HomeSilder/spiner";
import HomeSlider from "../Components/HomeSilder";
import StarRating from "../Components/rating";
import classNames from "classnames";

const HomePage = () => {
  useEffect(() => {
    document.title = "Hammer & Chhattery | Home";
    window.scrollTo(0, 0);
  }, []);
  const caseTypes = [
    <div>
      <i class="fas fa-balance-scale"></i>
      <p>Civil Cases</p>
    </div>,
    <div>
      <i class="fas fa-handcuffs"></i>

      <p>Criminal Cases</p>
    </div>,
    <div>
      <i class="fas fa-file-alt"></i>

      <p>Constitutional Cases</p>
    </div>,
    <div>
      <i class="fas fa-users"></i>

      <p>Family Cases</p>
    </div>,
    <div>
      <i class="fas fa-briefcase"></i>
      <p>Corporate and Commercial Cases</p>
    </div>,
    <div>
      <i class="fas fa-hard-hat"></i>
      <p>Labor and Employment Cases</p>
    </div>,
    <div>
      <i class="fas fa-calculator"></i>
      <p>Taxation Cases</p>
    </div>,
    <div>
      <i class="fas fa-hands"></i>
      <p>Human Rights Cases</p>
    </div>,
  ];

  const caseContents = [
    <ul>
      <li>
        <strong>Property Disputes: </strong>
        These involve disputes over property ownership, land boundaries, or
        inheritance issues.
      </li>
      <li>
        <strong>Contract Disputes: </strong>
        Cases arising from breaches of contract, whether for services, sales, or
        other agreements.
      </li>
      <li>
        <strong>Family Law Cases: </strong>
        These include divorce, child custody, maintenance, and guardianship
        matters.
      </li>
    </ul>,
    <ul>
      <li>
        <strong>Murder (Qatl): </strong>
        Cases involving the unlawful killing of a person, which can lead to
        severe penalties, including the death penalty or life imprisonment.
      </li>
      <li>
        <strong>Theft and Robbery: </strong>
        Cases involving theft, dacoity, or robbery, which are punishable under
        the Pakistan Penal Code.
      </li>
      <li>
        <strong>Blasphemy Cases: </strong>
        Serious charges related to blasphemy against Islam, which can lead to
        severe punishment under Pakistan’s blasphemy laws.
      </li>
    </ul>,
    <ul>
      <li>
        <strong>Fundamental Rights Violations: </strong>
        Cases filed in the High Court or Supreme Court regarding the violation
        of fundamental rights guaranteed by the Constitution of Pakistan.
      </li>
      <li>
        <strong>Judicial Review: </strong>
        Cases where the legality of a law or government action is challenged
        based on constitutional grounds.
      </li>
    </ul>,
    <ul>
      <li>
        <strong>Company Law Disputes: </strong>
        Disputes related to the management, shareholders, and governance of
        corporations, often handled by the Securities and Exchange Commission of
        Pakistan (SECP).
      </li>
      <li>
        <strong>Bankruptcy and Insolvency: </strong>
        Cases involving the insolvency of businesses or individuals and the
        subsequent legal proceedings.
      </li>
    </ul>,
    <ul>
      <li>
        <strong>Employment Disputes: </strong>
        Issues related to wrongful termination, discrimination, or wage disputes
        between employers and employees.
      </li>
      <li>
        <strong>Industrial Relations: </strong>
        Disputes between trade unions and employers, often involving collective
        bargaining or strikes.
      </li>
    </ul>,
    <ul>
      <li>
        <strong>Tax Evasion: </strong>
        Cases where individuals or businesses are accused of failing to pay due
        taxes, handled by the Federal Board of Revenue (FBR).
      </li>
      <li>
        <strong> Customs and Excise Disputes: </strong>
        Disputes related to the valuation and classification of imported goods,
        and the imposition of duties.
      </li>
    </ul>,
    <ul>
      <li>
        <strong>Pollution and Environmental Damage: </strong>
        Cases involving violations of environmental laws, including issues
        related to air and water pollution, deforestation, and industrial waste.
      </li>
    </ul>,
    <ul>
      <li>
        <strong>Women’s Rights: </strong>
        Cases involving issues like domestic violence, workplace harassment, and
        gender discrimination.
      </li>
      <li>
        <strong>Minority Rights: </strong>
        Legal battles regarding the protection of religious and ethnic
        minorities under Pakistani law.
      </li>
    </ul>,
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const handleCaseClick = (index) => {
    setActiveIndex(index);
  };
  const handleDownload = () => {
    const pdfFile = file;
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "18th-amendment.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={style.homepage}>
      <NavBar />
      <div>
        <div>
          <div className={style.heroSection}>
            <div className={style.heroWidth}>
              <p className={style.heroHeading}>
                Expert Legal Advice You Can Trust
              </p>
              <p className={style.heroSubhead}>
                Defending Your Rights with Dedication and Integrity
              </p>
              <p className={style.herotext}>
                With over 30 years of experience, our legal team is committed to
                providing personalized legal services tailored to your needs.
                Whether you're facing a complex legal issue or simply need
                guidance, we're here to help every step of the way.
              </p>
            </div>
          </div>
          <div className={style.mission}>
            <p className={style.misHeading}>
              Mission & Value Of Your Law Consult Firm
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                color: "#62688b",
              }}
            >
              Provide professional advice and guidance legal matters, helping
              clients understand their rights obligations, and potential courses
            </p>
            <div className={style.missionflex}>
              <div className={style.MissionImgOuter}>
                <img
                  src="	https://www.hoganeickhoff.com/wp-content/uploads/2020/07/Untitled-design-41.png"
                  className={style.missionImg}
                />
              </div>
              <div className={style.MissionImgOuter}>
                <div className={style.missionGird}>
                  <div className={style.missionCard}>
                    <p className={style.font40}>250+</p>
                    <p> Insurance Settlement</p>
                  </div>
                  <div className={style.missionCard}>
                    <p className={style.font40}>959+</p>
                    <p>Case Settlement</p>
                  </div>

                  <div className={style.missionCard}>
                    {" "}
                    <p className={style.font40}>388+</p>
                    <p>Personal Injury</p>
                  </div>

                  <div className={style.missionCard}>
                    {" "}
                    <p className={style.font40}>400+</p>
                    <p>Divorce Case</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.case}>
            <p className={styles.silderHeading} style={{ width: "100%" }}>
              Types of Cases in Pakistan's Legal System
            </p>
            <div className={styles.caseflex}>
              {caseTypes.map((type, index) => (
                <div
                  key={index}
                  className={`${styles.caseitem} ${
                    activeIndex === index ? styles.active : ""
                  }`}
                  onClick={() => setActiveIndex(index)}
                >
                  {type}
                </div>
              ))}
            </div>
            <div className={styles.contentOuter}>
              <div className={styles.content}>{caseContents[activeIndex]}</div>
            </div>{" "}
          </div>
          <section id="featured-book">
            <p className={style.misHeading} style={{ padding: "0px 20px" }}>
              Mission & Value Of Your Law Consult Firm
            </p>
            <div class={style.bookcontainer}>
              <div className={style.booksection}>
                <div style={{ marginBottom: "16px" }}>
                  <p className={style.booksubHEAD}>Name:</p>
                  <p className={style.bookHED}>18th AMENDMENT</p>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <p className={style.booksubHEAD}>Author:</p>
                  <p className={style.bookHED}>
                    MR. MUHAMMAD AMIR SOHAIL(M.A, L.L.B, L.L.M)
                  </p>
                </div>
                <div
                  style={{
                    marginBottom: "32px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <i
                    class="fa-solid fa-star"
                    style={{ color: "#ffa500", fontSize: "24px" }}
                  ></i>
                  <i
                    class="fa-solid fa-star"
                    style={{ color: "#ffa500", fontSize: "24px" }}
                  ></i>
                  <i
                    class="fa-solid fa-star"
                    style={{ color: "#ffa500", fontSize: "24px" }}
                  ></i>
                  <i
                    class="fa-solid fa-star"
                    style={{ color: "#ffa500", fontSize: "24px" }}
                  ></i>
                  <i
                    class="fa-solid fa-star"
                    style={{ color: "#ffa500", fontSize: "24px" }}
                  ></i>
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <p className={classNames(style.booksubHAD, style.fs15)}>
                    The amendment is notable for its provisions regarding the
                    distribution of resources among provinces, ensuring no
                    reduction in the National Finance Commission (NFC) share,
                    and addressing revenue distribution and provincial
                    royalties. It also introduced significant changes to the
                    political system, impacting the legislature, judiciary, and
                    executive branches of government. The amendment reinforced
                    federalism in Pakistan, altering the dynamics of
                    center-province relations and limiting military intervention
                    in governance. The text further outlines the historical
                    evolution of Pakistan's constitutions, starting from the
                    1956 Constitution, which established the country as an
                    Islamic Republic, to the 1962 Constitution, which
                    centralized power in the presidency. The 1973 Constitution,
                    drafted by an elected assembly, aimed to create a
                    parliamentary democracy. The 18th Amendment is positioned as
                    a critical step in reclaiming the constitutional framework
                    and promoting democratic governance in Pakistan.
                  </p>
                  <p></p>
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <button
                    className={style.BookDownload}
                    onClick={handleDownload}
                  >
                    <p>Download</p>
                    <i class="fas fa-download"></i>
                  </button>
                </div>
              </div>
              <div className={style.bookimgOuter}>
                <img src={book} />
              </div>
            </div>
          </section>

          <div>
            <p className={style.silderHeading}>
              Meet Our Expert Law Consulting Team
            </p>
            <HomeSlider />
          </div>
          <div style={{ marginBottom: "100px" }}>
            <Spinner />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
