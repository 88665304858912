import React from "react";
import style from "./privacypolicy.module.css";
import { MdOutlinePrivacyTip } from "react-icons/md";
import NavBar from "../../Components/NavBar";
import Footer from "../../Components/Footer";

export default function PrivacyPolicy() {
  return (
    <div>
      <NavBar />
      <div className={style.outer}>
        <div className={style.container}>
          <h1 className={style.Heading1}>Privacy Policy</h1>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}> Personal Information</h2>
            </div>
            <p className={style.abouttext}>
              We may collect personal information that you provide directly to
              us, such as:
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Mailing address</li>{" "}
              <li>Any other information you choose to provide</li>
            </ul>
          </div>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}>
                {" "}
                Cookies and Tracking Technologies
              </h2>
            </div>
            <p className={style.abouttext}>
              We may use cookies and similar tracking technologies to enhance
              your experience on our website. You can choose to disable cookies
              through your browser settings.
            </p>
          </div>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}>How We Use Your Information</h2>
            </div>
            <p className={style.abouttext}>
              We may use your information for various purposes, including:
            </p>
            <ul>
              <li>Providing and maintaining our services</li>
              <li>Responding to your inquiries or requests</li>
              <li>
                Sending you updates, newsletters, or promotional materials
              </li>
              <li>Improving our website and services</li>{" "}
              <li>Analyzing website usage and trends</li>
              <li>Complying with legal obligations</li>
            </ul>
          </div>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}> How We Share Your Information</h2>
            </div>
            <p className={style.abouttext}>
              We do not sell your personal information. We may share your
              information in the following circumstances:
            </p>
            <ul>
              <li>
                With service providers who help us operate our website or
                provide our services
              </li>
              <li>When required by law or to protect our legal rights</li>
              <li>
                In connection with a merger, acquisition, or sale of assets
              </li>
            </ul>
          </div>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}>Data Security</h2>
            </div>
            <p className={style.abouttext}>
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. However, no method
              of transmission over the internet or electronic storage is 100%
              secure, and we cannot guarantee absolute security.
            </p>
          </div>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}> Your Rights and Choices</h2>
            </div>
            <p className={style.abouttext}>
              You have the following rights regarding your personal information:
            </p>
            <ul>
              <li>
                <strong>Access:</strong>
                You can request a copy of the personal information we hold about
                you.
              </li>
              <li>
                <strong>Correction:</strong>
                You can ask us to correct any inaccurate or incomplete
                information.
              </li>
              <li>
                <strong>Deletion:</strong>
                You can request that we delete your personal information,
                subject to legal requirements.
              </li>
              <li>
                <strong>Opt-Out:</strong>
                You can opt-out of receiving marketing communications from us at
                any time. 6. Third-Party Links
              </li>
            </ul>
          </div>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}>Third-Party Links</h2>
            </div>
            <p className={style.abouttext}>
              Our website may contain links to third-party websites. We are not
              responsible for the privacy practices of those websites. We
              encourage you to read the privacy policies of any third-party
              sites you visit.
            </p>
          </div>{" "}
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}>Children's Privacy</h2>
            </div>
            <p className={style.abouttext}>
              Our services are not directed to individuals under the age of 18.
              We do not knowingly collect personal information from children. If
              we become aware that we have inadvertently collected personal
              information from a child, we will take steps to delete it.
            </p>
          </div>{" "}
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}>Changes to This Privacy Policy</h2>
            </div>
            <p className={style.abouttext}>
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page with an updated effective date. We
              encourage you to review this policy periodically.
            </p>
          </div>
          <div>
            <div className={style.flex}>
              <MdOutlinePrivacyTip className={style.icons} />
              <h2 className={style.Heading}>Contact Us</h2>
            </div>
            <p className={style.abouttext}>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:
            </p>
            <div>
              <p>Email: attorneyamir@gmail.com </p>
              <p>Phone: +92-42-36311007</p>

              <p>
                Address: 58 off Lawrence Road, Eissa Heights, Lahore, Pakistan
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
