import React from "react";
import styles from "./footer.module.css";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoTiktok, IoLogoYoutube } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { FaLinkedinIn } from "react-icons/fa";
export default function Footer() {
  const navigate = useNavigate();
  const handleGoToVender = () => {
    navigate("/privacyPolicy");
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerSection}>
          <h4 style={{ color: "#FFA500" }}>About Us</h4>
          <p>We are a company dedicated to providing the best service.</p>
        </div>
        <div className={styles.footerSection}>
          <h4 style={{ color: "#FFA500" }}>Links</h4>
          <ul className={styles.footerLinks}>
            <li style={{ cursor: "pointer" }}>
              <a href="/">Home</a>
            </li>
            <li style={{ cursor: "pointer" }}>
              <a href="/aboutUs">About</a>
            </li>
            <li style={{ cursor: "pointer" }}>
              <a href="/service">Services</a>
            </li>
            <li style={{ cursor: "pointer" }}>
              <a href="/contactUs">Contact</a>
            </li>
          </ul>
        </div>
        <div className={styles.footerSection}>
          <h4 style={{ color: "#FFA500" }}>Contact Us</h4>
          <p>Email: attorneyamir@gmail.com </p>
          <p>Phone: +92-42-3631107</p>
          <p>Cell: 0333-4241107</p>
        </div>
        <div className={styles.footerSection}>
          <h4 style={{ color: "#FFA500" }}>Company</h4>
          <p style={{ cursor: "pointer" }} onClick={handleGoToVender}>
            Privacy & Policy
          </p>
          <p>Terms And Conditions</p>
        </div>
      </div>
      <div className={styles.socialIocns}>
        <a href="https://www.facebook.com/amir.sohail.488?mibextid=ZbWKwL">
          <FaFacebookF className={styles.Iocns} />
        </a>
        <a href="https://www.linkedin.com/in/amir-sohail?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
          <FaLinkedinIn className={styles.Iocns} />
        </a>
      </div>
      <div className={styles.footerBottom}>
        <p>&copy; 2024 Hammer & Chhattery. All rights reserved.</p>
      </div>
    </footer>
  );
}
