import React from "react";
import Carousel from "react-multi-carousel";
import classNames from "classnames";
import "react-multi-carousel/lib/styles.css";
import style from "./homeslider.module.css";
import SWAT from "../../assests/Zulfi.jpeg";
import HUNZA from "../../assests/Tahmina.jpeg";
import SKARDU from "../../assests/touseef.jpeg";
import UMBRELLA from "../../assests/Sarfraz.jpeg";
import NATHIA from "../../assests/Capture.PNG";
import SALT from "../../assests/bilalAzam.png";
import FAIRY from "../../assests/Userr2.jpeg";
import GILGIT from "../../assests/bilalAzam.png";
import PishinValley from "../../assests/Userr2.jpeg";

import { Laptop } from "@mui/icons-material";

const responsive = {
  lgdesktop: {
    breakpoint: { max: 3000, min: 1441 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1041 },
    items: 4,
    slidesToSlide: 1,
  },
  Laptop: {
    breakpoint: { max: 1040, min: 769 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 481 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 480, min: 320 },
    items: 1,
    slidesToSlide: 1,
  },
};

const sliderItems = [
  {
    imageUrl: SWAT,
    Heading: "Zulfiqar Ahmad",
    text: "Advocate High Court",
  },
  {
    imageUrl: HUNZA,
    Heading: "Tahmina",
    text: "Advocate High Court",
  },
  {
    imageUrl: SKARDU,
    Heading: "Touseef Haider",
    text: "Advocate High Court",
  },
  {
    imageUrl: UMBRELLA,
    Heading: "M. Asghar Zia ",
    text: "LLM UK (Director of law SOL)",
  },
  {
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUOdfo4lewXJYT_2xPo_Xu2Lj6XPn78X9UJA&s",
    Heading: "Sarfaraz Gondal",
    text: "Advocate Supreme Court",
  },
];

const HomeSlider = () => {
  return (
    <div className="parent" style={{ marginBottom: "24px" }}>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        centerMode={true}
        showDots={false}
        infinite={true}
        dotListClass="custom-dot-list-style"
      >
        {sliderItems.map((item, index) => (
          <div className={style.slider} key={index}>
            <img src={item.imageUrl} className={style.imgs} />
            <p className={classNames(style.heading)}>{item.Heading}</p>
            <p className={classNames(style.text)}>{item.text}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeSlider;
