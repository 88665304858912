import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import style from "./aboutUs.module.css";
import classNames from "classnames";
import Footer from "../Components/Footer";
import Founder from "../assests/Founder.jfif";
import { PiSealCheckBold } from "react-icons/pi";
export default function AboutUs() {
  useEffect(() => {
    document.title = "Hammer & Chhattery | About Us";
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <NavBar />
      <div className={style.outer}>
        <div className={style.container}>
          <h1 className={style.Heading1}>
            MR. MUHAMMAD AMIR SOHAIL(M.A, L.L.B; L.L.M;)
          </h1>
          <div className={style.aboutOuter}>
            <div className={style.founderCard}>
              <img src={Founder} className={style.founderImg} />
            </div>
            <div className={classNames(style.abouttext, style.w50)}>
              <p style={{ color: "#62688b" }}>
                At Hammer & Chhattery, we bring decades of legal expertise to
                the forefront, delivering unmatched representation in complex
                legal matters across multiple jurisdictions. Founded and led by
                Muhammad Amir Sohail, a seasoned attorney with a distinguished
                career spanning over three decades, our firm has a proven track
                record in handling high-stakes litigation and intricate legal
                challenges. With a foundation built on rigorous academic
                qualifications and a deep commitment to the rule of law, our
                practice is characterized by a relentless pursuit of justice and
                a meticulous approach to legal strategy.
              </p>
              <p style={{ marginTop: "10px", color: "#62688b" }}>
                Our international experience, including collaborations with top
                legal offices in the UK, Cyprus, Canada, and beyond, equips us
                with a global perspective that enhances our ability to navigate
                the intricacies of both domestic and international law. We pride
                ourselves on offering personalized legal services that are
                tailored to meet the specific needs of our clients, whether they
                are individuals, corporations, or government entities. At Hammer
                & Chhattery, our mission is to uphold the highest standards of
                legal excellence, ensuring that every case is handled with the
                utmost professionalism, integrity, and dedication.
              </p>
              <p style={{ marginTop: "10px", color: "#62688b" }}>
                At Hammer & Chhattery, our mission is to uphold the highest
                standards of legal excellence, ensuring that every case is
                handled with the utmost professionalism, integrity, and
                dedication. We are committed to providing our clients with
                clear, strategic advice and effective advocacy, always
                prioritizing their best interests.
              </p>
            </div>
          </div>
          <h2 className={style.Heading}>Memberships</h2>
          <div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Life Member, Supreme Court Bar Association, Islamabad, Pakistan.
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Life Member, Lahore High Court Bar Association, Punjab,
                Pakistan.
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Life Member, Lahore Bar Association, Lahore
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Life Member, Lahore Tax Bar Association, Lahore
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Member, International Bar Association, U.K.
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Member, American Bar Association, Chicago, USA.
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Member Commonwealth Bar Association U.K
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Chairman, Consumer Protection Society, Lahore.
              </p>
            </div>
            <div className={style.flex}>
              <PiSealCheckBold className={style.icons} />
              <p className={style.listtext}>
                Member, Human Rights Commission, UK
              </p>
            </div>
          </div>
          <div>
            <h2 className={style.Heading}>Our Founding Vision</h2>
            <p className={style.abouttext}>
              Hammer & Chhattery was founded on the principle that every client
              deserves the highest standard of legal representation. With a deep
              understanding of the law, a strategic mindset, and a passion for
              justice, we strive to offer solutions that are not only legally
              sound but also practical and aligned with our clients' goals. Our
              firm is committed to fostering long-term relationships with our
              clients, built on trust, transparency, and unwavering dedication.
            </p>
          </div>
          <div>
            <h2 className={style.Heading}>Our Expertise</h2>
            <p className={style.abouttext}>
              With a rich portfolio of experience spanning domestic and
              international jurisdictions, Hammer & Chhattery is uniquely
              positioned to handle a wide range of legal challenges. Our
              expertise includes, but is not limited to, corporate law,
              commercial litigation, international arbitration, intellectual
              property, tax law, energy law, and human rights. We have
              successfully represented clients in high-stakes cases before the
              Supreme Court of Pakistan, as well as in courts across the UK,
              Cyprus, Canada, and other global venues. Our deep knowledge of
              local and international legal frameworks allows us to provide
              insightful and effective counsel on even the most complex legal
              issues.
            </p>
          </div>
          <div>
            <h2 className={style.Heading}>Our Approach</h2>
            <p className={style.abouttext}>
              At Hammer & Chhattery, we believe that effective legal
              representation goes beyond the courtroom. It involves
              understanding the unique needs of each client, developing tailored
              strategies, and delivering results that make a tangible
              difference. We approach each case with meticulous attention to
              detail, ensuring that every aspect is thoroughly examined and
              strategically addressed. Our team is committed to continuous
              learning and staying at the forefront of legal developments,
              enabling us to offer cutting-edge solutions to our clients
            </p>
          </div>{" "}
          <div>
            <h2 className={style.Heading}>Our Commitment to Clients</h2>
            <p className={style.abouttext}>
              Our clients are at the heart of everything we do. We understand
              that legal issues can be challenging and stressful, which is why
              we are dedicated to providing not only expert legal advice but
              also compassionate support throughout the process. We take the
              time to listen to our clients, understand their concerns, and work
              collaboratively to achieve the best possible outcomes. Our firm is
              known for its client-centric approach, where every client is
              treated with the respect, attention, and care they deserve.
            </p>
          </div>{" "}
          <div>
            <h2 className={style.Heading}>Our Global Reach</h2>
            <p className={style.abouttext}>
              In an increasingly interconnected world, legal issues often
              transcend borders. Hammer & Chhattery has built a strong network
              of international legal partners, enabling us to offer
              comprehensive legal services on a global scale. Whether you are a
              multinational corporation navigating cross-border transactions or
              an individual seeking justice in a foreign jurisdiction, our firm
              has the expertise and resources to assist you. We are proud of our
              ability to seamlessly integrate local knowledge with global
              insight, providing our clients with a strategic advantage in the
              international legal arena.
            </p>
          </div>{" "}
          <div>
            <h2 className={style.Heading}>Our Values</h2>
            <p className={style.abouttext}>
              Integrity, excellence, and dedication are the core values that
              drive Hammer & Chhattery. We are committed to upholding the
              highest ethical standards in all our dealings and maintaining a
              steadfast commitment to the rule of law. Our firm operates with a
              strong sense of social responsibility, actively engaging in pro
              bono work and contributing to the development of the legal
              profession through education, advocacy, and thought leadership
            </p>
          </div>{" "}
          <div>
            <h2 className={style.Heading}>Join Us on the Journey to Justice</h2>
            <p className={style.abouttext}>
              At Hammer & Chhattery, we are more than just a law firm – we are
              your partners in the pursuit of justice. Whether you are facing a
              legal challenge or seeking to safeguard your interests, our team
              is here to provide the support and expertise you need. We invite
              you to join us on this journey and experience the difference that
              our passion for law and commitment to excellence can make.
            </p>
          </div>{" "}
          <div style={{ marginTop: "24px" }}>
            <p className={style.abouttext}>
              Thank you for considering Hammer & Chhattery as your legal
              advisors. We look forward to the opportunity to serve you and help
              you achieve your legal goals.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
